<template>
    <div class="tabbar">
    	<div class="tabbar-item" @click="to('/h5')">
    		<img v-if="activeId == 0" src="@/assets/images/h5/common/home-active.png"></img>
    		<img v-else src="@/assets/images/h5/common/home.png"></img>
    		<div :style="{color: activeId == 0 ? '#5466EF':'#A1A2AA'}">{{$t('Module.Home')}}</div>
    	</div>
    	<div class="tabbar-item" @click="to('/h5/PerpetualContract')">
    		<img v-if="activeId == 1" src="@/assets/images/h5/common/contract-active.png"></img>
    		<img v-else src="@/assets/images/h5/common/contract.png"></img>
    		<div :style="{color: activeId == 1 ? '#5466EF':'#A1A2AA'}">{{$t('Module.PerpetualContract')}}</div>
    	</div>
    	<div class="tabbar-item" @click="to('/h5/CurrencyTrading')">
    		<img v-if="activeId == 2" src="@/assets/images/h5/common/coin-active.png"></img>
    		<img v-else src="@/assets/images/h5/common/coin.png"></img>
    		<div :style="{color: activeId == 2 ? '#5466EF':'#A1A2AA'}">{{$t('Module.CurrencyTrading')}}</div>
    	</div>
    	<div class="tabbar-item" @click="to('/h5/SecondContract')">
    		<img v-if="activeId == 3" src="@/assets/images/h5/common/second-contract-active.png"></img>
    		<img v-else src="@/assets/images/h5/common/second-contract.png"></img>
    		<div :style="{color: activeId == 3 ? '#5466EF':'#A1A2AA'}">{{$t('Module.SecondContract')}}</div>
    	</div>
    	<div class="tabbar-item" @click="to('/h5/assets')">
    		<img v-if="activeId == 4" src="@/assets/images/h5/common/assets-active.png"></img>
    		<img v-else src="@/assets/images/h5/common/assets.png"></img>
    		<div :style="{color: activeId == 4 ? '#5466EF':'#A1A2AA'}">{{$t('Module.Assets')}}</div>
    	</div>
    </div>
</template>

<script>
    export default {
        props: {
            activeId: {
                type: Number,
                default: 0
            }
        },
        data() {
        	return {
        		
        	}
        },
        methods: {
        	to(path) {
        		this.$router.push({path})
        	}
        }
    }
</script>

<style lang="scss" scoped>
    .tabbar {
    	position: fixed;
    	bottom: 0;
    	left: 0;
        z-index: 40;
    	display: flex;
    	justify-content: space-between;
    	width: 100%;
    	height: 6rem;
    	background-color: #FFFFFF;
    	
    	
    	.tabbar-item {
            flex: 1;
    		display: flex;
    		flex-direction: column;
    		justify-content: center;
    		align-items: center;
    		
    		img {
                width: 2rem;
    			height: 2rem;			
    		}
    		
    		div {
    			margin-top: 5px;
    			font-size: 1.2rem;
                line-height: 110%;
    			text-align: center;
    			word-wrap: break-word;
    		}
    	}
    }
</style>